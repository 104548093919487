/* #region Imports */
import React, { useRef, useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/css/custom.css";
import "./assets/css/utilityClasses.css";
import Logo from "./assets/images/logologin.png";
import FooterLogo from "./assets/images/logologinFooter.png";
import BrandNavigation from "./component/layouts/BrandDashboardHeader";
import BrandAside from "./component/layouts/BrandAside";
import BrandFooter from "./component/layouts/BrandFooter";
import StockPage from "./component/stock/index";
import ImsList from "./component/stock/imsList";
import UserList from "./component/users/userList";
import * as Library from "./utils/Library";
import Account from "./component/users/account";

const Login = lazy(() => import("./component/authentication/login"));
const InviteBrand = lazy(() => import("./component/brands/index"));
const InviteBrandList = lazy(() => import("./component/brands/inviteList"));
/* #endregion */

function App() {
    /* #region  VARS */
    if (["development", "testing", "production", "staging"].includes(process.env.NODE_ENV)) {
        window.BOLibrary = Library;
    }

    const imageData = {
        logo: Logo,
        footer_logo: FooterLogo
    };

    const ref = useRef();
    const [showMaster, setShowMaster] = useState(false);
    const token = localStorage.getItem("token");
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        if (token) {
            setShowMaster(true);
        }
    }, [token]);
    /* #endregion */

    /* #region  METHODS */
    const headerFooterFun = type => {
        if (type === "hide") {
            setShowMaster(false);
        } else if (type === "show") {
            setShowMaster(true);
        }
    };
    /* #endregion */

    return (
        <BrowserRouter>
            <div className="wrapper" ref={ref} id="myscreen">
                {showMaster && (
                    <>
                        <BrandNavigation masterLayoutFun={headerFooterFun} />
                        <BrandAside />
                    </>
                )}

                <Suspense fallback={<div>..</div>}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <PublicRoute>
                                    <Login imageData={imageData} masterLayoutFun={headerFooterFun} />
                                </PublicRoute>
                            }
                            exact={true}
                        />

                        <Route
                            path="/invite-brand"
                            element={
                                <PrivateRoute>
                                    <InviteBrand imageData={imageData} brandType={"label-brands"} />
                                </PrivateRoute>
                            }
                            exact={true}
                        />

                        <Route
                            path="/invite-brand/label-brands"
                            element={
                                <PrivateRoute>
                                    <InviteBrand imageData={imageData} brandType={"label-brands"} />
                                </PrivateRoute>
                            }
                            exact={true}
                        />

                        <Route
                            path="/invite-brand/retail-brands"
                            element={
                                <PrivateRoute>
                                    <InviteBrand imageData={imageData} brandType={"retail-brands"} />
                                </PrivateRoute>
                            }
                            exact={true}
                        />

                        <Route
                            path="/invite-brand/list"
                            element={
                                <PrivateRoute>
                                    <InviteBrandList imageData={imageData} />
                                </PrivateRoute>
                            }
                            exact={true}
                        />

                        <Route
                            path="/invite-brand/label-brands/list"
                            element={
                                <PrivateRoute>
                                    <InviteBrandList imageData={imageData} brandType={"label-brands"} />
                                </PrivateRoute>
                            }
                            exact={true}
                        />

                        <Route
                            path="/invite-brand/retail-brands/list"
                            element={
                                <PrivateRoute>
                                    <InviteBrandList imageData={imageData} brandType={"retail-brands"} />
                                </PrivateRoute>
                            }
                            exact={true}
                        />

                        <Route
                            path="/stock"
                            element={
                                <PrivateRoute>
                                    <StockPage imageData={imageData} />
                                </PrivateRoute>
                            }
                            exact={true}
                        />

                        <Route
                            path="/stock/ims-list"
                            element={
                                <PrivateRoute>
                                    <ImsList imageData={imageData} />
                                </PrivateRoute>
                            }
                            exact={true}
                        />

                        <Route
                            path="/user-list"
                            element={
                                <PrivateRoute>
                                    <UserList imageData={imageData} />
                                </PrivateRoute>
                            }
                            exact={true}
                        />

                        <Route
                            path="/account"
                            element={
                                <PrivateRoute>
                                    <Account imageData={imageData} />
                                </PrivateRoute>
                            }
                            exact={true}
                        />
                    </Routes>
                </Suspense>

                {showMaster && (
                    <>
                        <BrandFooter logo={imageData.footer_logo} />
                    </>
                )}
            </div>
        </BrowserRouter>
    );
}

export default App;

/* #region  SMALL COMPONENTS */

function PrivateRoute({ children }) {
    const auth = localStorage.getItem("token");
    return auth ? children : <Navigate to="/" />;
}

function PublicRoute({ children }) {
    const auth = localStorage.getItem("token");
    return auth ? <Navigate to="/invite-brand" /> : children;
}
/* #endregion */
